<template>
  <div class="default-cover-wrap">
    <img src="../../assets/img/logo.png" />
  </div>
</template>

<script>
export default {
  name: 'default-cover'
};
</script>

<style lang="scss" scoped>
.default-cover-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  img {
    width: 43px;
    height: 43px;
  }
}
</style>
