import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Loading from '@/components/loading';
import BaiduMap from 'vue-baidu-map';

import '@/assets/font/font.css';
import DefaultCover from '@/views/modules/default-cover';
import filter from '@/utils/filter';
Vue.use(filter);

Vue.use(ElementUI);
Vue.component('loading', Loading);
Vue.component('default-cover', DefaultCover);
Vue.use(BaiduMap, { ak: '193da7f3602bfd106c9bc2433d15337a' });
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
