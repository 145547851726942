// import Vue from 'vue';

// ---START-普通缓存处理-START---
export function setLocalStorage(title, info) {
  window.localStorage.setItem(title, JSON.stringify(info));
}

export function setSessionStorage(title, info) {
  window.sessionStorage.setItem(title, JSON.stringify(info));
}

export function getLocalStorage(title) {
  if (!isVariableEmpty(window.localStorage.getItem(title))) {
    return JSON.parse(window.localStorage.getItem(title));
  }
  return null;
}

export function getSessionStorage(title) {
  if (!isVariableEmpty(window.sessionStorage.getItem(title))) {
    return JSON.parse(window.sessionStorage.getItem(title));
  }
  return null;
}

export function removeLocalStorage(title) {
  if (!isVariableEmpty(window.localStorage.getItem(title))) {
    window.localStorage.removeItem(title);
  }
}

export function removeSessionStorage(title) {
  if (!isVariableEmpty(window.sessionStorage.getItem(title))) {
    window.sessionStorage.removeItem(title);
  }
}
// ---END-普通缓存处理-END---
/**
 * 登录状态
 * */
export function getLoginCondition() {
  let login = false;
  if (!isVariableEmpty(getLocalStorage('LoginState'))) {
    login = getLocalStorage('LoginState');
  }
  return login;
}
/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  removeLocalStorage('LoginUser');
  setLocalStorage('LoginState', false);
}
/**
* 变量是否为空
* */
export function isVariableEmpty(variable) {
  return (variable === null || variable === undefined || variable === 'null' || variable === 'undefined' || variable === '');
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/.test(s);
}

export function parseDom(html) {
  const ele = document.createElement('div');
  ele.innerHTML = html;
  return ele.childNodes[0];
}

export function cookieSet(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + d.toGMTString();
  document.cookie = cname + '=' + escape(cvalue) + '; ' + expires;
}
