<template>
  <div v-if="isLoad" class="loading-page">
    <div class="loading-region">
      <div class="loading-blank">
        <div class="loading-circle-first"></div>
        <div class="loading-circle-secondary"></div>
        <div class="loading-circle-third"></div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'loading',
  props: {
    isLoad: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">

.loading-page {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  z-index: 1000;
  @keyframes loading-circle-flash {
    0% {
      background: rgba(0, 164, 255, 0.2);
    }
    100% {
      background: rgba(0, 164, 255, 1);
    }
  }
  @keyframes loading-circle-flash-infinite {
    0% {
      background: rgba(0, 164, 255, 0.2);
    }
    100% {
      background: rgba(0, 164, 255, 1);
    }
  }
  @keyframes loading-circle-flash-third {
    0% {
      background: rgba(0, 164, 255, 0.2);
    }
    100% {
      background: rgba(0, 164, 255, 1);
    }
  }
  .loading-region {
    width: 100px;
    height: 100px;
    margin-top: -100px;
    margin-left: -50px;
    position: absolute;
    top: 50%;
    left: 50%;
    .loading-blank {
      width: 100px;
      height: 100px;
      position: relative;
      .loading-circle-first {
        animation: loading-circle-flash 1s linear infinite;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #00A4FF;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .loading-circle-secondary {
        animation: loading-circle-flash-infinite 2s linear infinite;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #00A4FF;
        position: absolute;
        top: 0;
        right: 24px;
        bottom: 12px;
        left: 24px;
      }
      .loading-circle-third {
        animation: loading-circle-flash-third 2.5s linear infinite;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #00A4FF;
        position: absolute;
        top: 0;
        right: 48px;
        bottom: 12px;
        left: 48px;
      }
    }
  }
}
</style>
