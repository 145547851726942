import Vue from 'vue';
import VueRouter from 'vue-router';
import { getLoginCondition } from '@/utils/index';

const original = VueRouter.prototype.push;
VueRouter.prototype.push = function navigate(target, resolve, reject) {
  if (resolve || reject) {
    return original.call(this, target, resolve, reject);
  }
  return original.call(this, target).catch(error => error);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/main'),
    name: 'Main',
    redirect: { name: 'home' },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/homePage'),
        meta: { title: '首页', index: 0 }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: { title: '登录' }
      },
      {
        path: '/bindMobile',
        name: 'bindMobile',
        component: () => import('@/views/bindMobile'),
        meta: { title: '登录' }
      },
      {
        path: '/appDownload',
        name: 'appDownload',
        component: () => import('@/views/appDownload'),
        meta: { title: 'APP下载', index: 2 }
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs'),
        meta: { title: '关于我们' }
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/contactUs'),
        meta: { title: '联系我们' }
      },
      {
        path: '/liveList',
        name: 'liveList',
        component: () => import('@/views/liveList'),
        meta: { title: '直播列表', index: 1 }
      },
      {
        path: '/myLearning',
        name: 'myLearning',
        component: () => import('@/views/myLearning'),
        meta: { title: '我的学习' }
      },
      {
        path: '/courseSelection',
        name: 'courseSelection',
        component: () => import('@/views/courseSelection'),
        meta: { title: '选课', index: 1 }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search'),
        meta: { title: '搜索结果', index: 0 }
      },
      {
        path: '/moduleList',
        name: 'moduleList',
        component: () => import('@/views/moduleList'),
        meta: { index: 1 }
      },
      {
        path: '/courseDetail',
        name: 'courseDetail',
        component: () => import('@/views/courseDetail'),
        meta: { title: '课程详情', index: 1 }
      },
      {
        path: '/packageDetail',
        name: 'packageDetail',
        component: () => import('@/views/packageDetail'),
        meta: { title: '套课详情', index: 1 }
      },
      {
        path: '/teacherDetail',
        name: 'teacherDetail',
        component: () => import('@/views/teacherDetail'),
        meta: { index: 1 }
      }
    ]
  },
  {
    path: '/videoDetail',
    component: () => import('@/views/videoDetail'),
    name: 'videoDetail'
  },
  {
    path: '/videoPlayer',
    component: () => import('@/views/videoPlayer'),
    name: 'videoPlayer'
  },
  {
    path: '/coursePlayer',
    component: () => import('@/views/coursePlayer'),
    name: 'coursePlayer'
  },
  {
    path: '/callback/qq',
    component: () => import('@/views/callback/qq'),
    name: 'qq'
  },
  {
    path: '/callback/wechat',
    component: () => import('@/views/callback/wechat'),
    name: 'wechat'
  },
  {
    path: '/live',
    component: () => import('@/views/live'),
    name: 'live'
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach(function(to, from, next) {
  // 判断是否需要登录才可以进入
  if (to.matched.some((_) => _.meta.auth)) {
    // 判断是否登录
    if (getLoginCondition()) {
      next();
    }
    else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
  else {
    // 不需要身份校验 直接通过
    next();
  }
});

export default router;
